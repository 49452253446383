<template>
    <div id="pageList" class="userList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">账号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入账号模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入名称模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">人员：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('personId',el)" v-model="pageList.queryParam.personId" placeholder="请选择所属人员" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'personId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">状态：</el-col>
                    <el-col :span="9">
                        <el-select v-model="pageList.queryParam.status" placeholder="请选择" style="width: 100%" clearable>
                            <el-option label="启用" value="0"/>
                            <el-option label="禁用" value="1"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>

            <template v-slot:tbCols>
                <el-table-column prop="F_CODE" label="账号" sortable='true'/>
                <el-table-column prop="F_NAME" label="名称" sortable='custom'/>
                <el-table-column prop="F_CORP_NAME" label="机构"/>
                <el-table-column prop="F_PERSON_NAME" label="人员"/>
                <el-table-column prop="F_STATUS" label="状态" :formatter="formatterStatus"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import UserListHelper from "./UserListHelper.js";

    export default UserListHelper;
</script>

<style scoped>
    .userList{width: 100%;}
</style> 
